import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import upload from '@/components/upload.vue';
import { addOrUpdateVisitor } from '@/api/administration';
import { selectJobCascade } from '@/api/material';
import Cookies from "js-cookie";
import moment from 'moment';
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    upload,
    ProfileOutlined,
    DollarOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      id: null,
      visitorName: null,
      visitorCompany: null,
      mobilePhone: null,
      vehicleInfo: null,
      bidDate: [],
      visitReason: null,
      visitorIcon: null,
      receptUid: null,
      receptWorkno: null,
      receptDepartemntid: null,
      receptDepartemntName: null,
      visitorSource: 0,
      type: 1
    });
    const {
      checkName,
      checkPhone
    } = getFieldRules();
    const rules = {
      receptUid: [{
        required: true,
        message: '接待人不能为空',
        trigger: 'change',
        type: 'array'
      }],
      visitorName: [{
        required: true,
        validator: checkName,
        trigger: 'blur'
      }],
      mobilePhone: [{
        required: true,
        validator: checkPhone,
        trigger: 'blur'
      }],
      bidDate: [{
        required: true,
        message: '访问期限不能为空',
        trigger: 'change',
        type: 'array'
      }],
      visitorCompany: [{
        required: true,
        message: '访客公司不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      visitReason: [{
        required: true,
        message: '访问事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };
    const loading = ref(false); //提交

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, formState);
        obj.receptUid = formState.receptUid[1];
        obj.startTime = formState.bidDate[0];
        obj.endTime = formState.bidDate[1];
        loading.value = true;
        let res = await addOrUpdateVisitor(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
        loading.value = false;
      });
    };

    const headerImgUrl = val => {
      console.log(val);
      formState.visitorIcon = val;
    };

    const personnelData = ref([]);

    const getPersonnelData = async () => {
      let param = {
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectJobCascade(param);

      if (res.code === 200) {
        const jobProjectsAll = res.data.jobProjectsAll;
        personnelData.value = conversion(jobProjectsAll);
      } else {
        _message.error(res.message);
      }
    };

    const conversion = val => {
      const arr = [];
      let myMap = {};
      val.forEach(item => {
        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            let obj = {
              value: ixt.uid,
              // value:`${ixt.uid}-${ixt.departmentName}-${ixt.workNo}`,
              label: ixt.userName,
              departmentId: ixt.departmentId,
              departmentName: ixt.departmentName,
              workNo: ixt.workNo
            };

            if (!myMap[ixt.departmentId]) {
              let obj1 = {
                // value: `${ixt.departmentName}-${ixt.departmentId}-${ixt.workNo}`,
                value: ixt.departmentId,
                label: ixt.departmentName,
                children: []
              };
              obj1.children.push(obj);
              arr.push(obj1);
              myMap[ixt.departmentId] = ixt.departmentId;
            } else {
              arr.forEach(ixts => {
                if (ixts.value === ixt.departmentId) {
                  ixts.children.push(obj);
                }
              });
            }
          });
        }
      });
      return arr;
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handleChange1 = val => {
      // console.log(val)
      if (val) {
        personnelData.value.forEach(item => {
          if (item.value === val[0]) {
            item.children.forEach(ixt => {
              if (ixt.value === val[1]) {
                formState.receptDepartemntName = ixt.departmentName;
                formState.receptDepartemntid = ixt.departmentId;
                formState.receptWorkno = ixt.workNo;
              }
            });
          }
        });
      } else {
        formState.receptDepartemntName = null;
        formState.receptDepartemntid = null;
        formState.receptWorkno = null;
      }
    };

    const range = (start, end) => {
      const result = [];

      for (let i = start; i < end; i++) {
        result.push(i);
      }

      return result;
    };

    const disabledDateTime = (dates, type) => {
      let hours = moment().hours(); //0~23

      let minutes = moment().minutes(); //0~59

      let seconds = moment().seconds(); //0~59
      //当日只能选择当前时间之后的时间点

      if (type === 'start') {
        if (dates && dates.length) {
          if (moment(dates[0]).date() === moment().date()) {
            if (moment(dates[0]).hours() === moment().hours()) {
              if (moment(dates[0]).minutes() === moment().minutes()) {
                return {
                  disabledHours: () => range(0, hours),
                  disabledMinutes: () => range(0, minutes + 1),
                  disabledSeconds: () => range(0, seconds + 1)
                };
              } else {
                return {
                  disabledHours: () => range(1, hours),
                  disabledMinutes: () => range(0, minutes + 1)
                };
              }
            } else {
              return {
                disabledHours: () => range(0, hours)
              };
            }
          }
        }
      }
    };

    onMounted(() => {
      getPersonnelData();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      disabledDate,
      headerImgUrl,
      personnelData,
      getPersonnelData,
      handleChange1,
      disabledDateTime
    };
  }

});