import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick } from 'vue';
import useTablePagination from '@/hooks/pagination';
import applyModal from './components/visitorModal.vue';
import visitorDetailModal from './components/visitorDetailModal.vue';
import visitorAgainModal from './components/visitorAgainModal.vue';
import visitorList from './components/visitorList.vue';
import Cookies from "js-cookie";
import { selectVisitorByPagination, deleteVisitor, updateVisitorState } from '@/api/administration';
import { useStore } from 'vuex';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import moment from 'moment'; //表头

const columns = [{
  title: '访问编号',
  dataIndex: 'visitorNo',
  align: 'center'
}, {
  title: '访问姓名',
  dataIndex: 'visitorName',
  align: 'center'
}, {
  title: '访问公司',
  dataIndex: 'visitorCompany',
  align: 'center'
}, {
  title: '手机号',
  dataIndex: 'mobilePhone',
  align: 'center',
  width: '130px'
}, {
  title: '接待人',
  dataIndex: 'receptName',
  align: 'center'
}, {
  title: '访问期限',
  dataIndex: 'time',
  width: '340px',
  align: 'center'
}, {
  title: '状态',
  dataIndex: 'visitorState',
  align: 'center',
  slots: {
    customRender: 'visitorState'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  align: 'center',
  width: '180px',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    applyModal,
    visitorDetailModal,
    QuestionCircleOutlined,
    visitorAgainModal,
    visitorList
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      visitorNo: null,
      visitorName: null,
      visitorCompany: null,
      receptName: null,
      date: []
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([// {
      //   endTime:'2022-12-30',
      //   state:44
      // },
      // {
      //   endTime:'2022-12-01',
      //   state:44
      // },
      // {
      //   endTime:'2022-12-01',
      //   state:47
      // },
      // {
      //   endTime:'2022-12-01',
      //   state:46
      // },
    ]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const {
        date
      } = formState;
      const [startTime, endTime] = date.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD');
        }

        return item.startOf('day').format('YYYY-MM-DD');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        visitorNo: formState.visitorNo,
        visitorName: formState.visitorName,
        visitorCompany: formState.visitorCompany,
        receptName: formState.receptName,
        startDate: startTime ? startTime : null,
        endDate: endTime ? endTime : null
      };
      loading.value = true;
      let res = await selectVisitorByPagination(param);

      if (res.code === 200) {
        const arr = [];
        tableData.value = [];
        if (res.data.list) res.data.list.forEach(item => {
          arr.push({ ...item,
            key: item.id,
            time: `${item.startTime}至${item.endTime}`
          });
        });
        Object.assign(tableData.value, arr);
        total.value = res.data.countSize;
        current.value = res.data.nowPage;
        pageSize.value = res.data.pageSize;
        loading.value = false;
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //发起申请


    const applyVisible = ref(false);

    const handleApply = () => {
      applyVisible.value = true;
    }; //关闭申请弹窗


    const closeApplyVisible = val => {
      applyVisible.value = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    }; //详情


    const applyDetailVisible = ref(false);
    const applyDetailData = reactive({
      state: 1,
      // data:{}
      key: null,
      applyVisible: false
    }); //查看详情

    const toView = async (item, num) => {
      applyDetailData.state = num;
      applyDetailData.key = item.key;
      applyDetailData.applyVisible = true;
    }; //关闭详情页


    const closeApplyDetailVisible = val => {
      applyDetailData.applyVisible = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    };

    const updateDataValue = val => {
      applyDetailData.data = val;
    };

    const setBtnIsShow = (val, num) => {
      if (val.visitorState == 44) {
        if (moment(val.endTime).subtract(1, 'days') >= moment().subtract(1, 'days')) {
          if (num == 1) {
            return true;
          } else {
            return false;
          }
        } else {
          if (num == 1) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    };

    const setDeleteTitle = val => {
      if (moment(val.endTime).subtract(1, 'days') >= moment().subtract(1, 'days')) {
        return '删除后将取消邀请，是否确认删除？';
      } else {
        return '是否确认删除？';
      }
    };

    const deleteItem = async id => {
      let res = await deleteVisitor({
        id
      });

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    const updateItem = async (applyId, visitorType) => {
      let res = await updateVisitorState({
        applyId,
        visitorType
      });

      if (res.code === 200) {
        _message.success(res.message);

        getTableData();
      } else {
        _message.error(res.message);
      }
    }; //再次邀请


    const applyAgainVisible = ref(false);
    const applyAgainData = reactive({
      key: null,
      applyVisible: false
    }); //查看再次邀请

    const handleAgain = async key => {
      applyAgainData.key = key;
      applyAgainData.applyVisible = true;
    }; //关闭再次邀请页


    const closeApplyAgainVisible = val => {
      applyAgainData.applyVisible = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    };

    const signVisible = ref(false);
    const signState = ref({
      applyId: null,
      content: null,
      visitorType: 1,
      visitorTitme: null,
      visitorNo: null,
      visitorName: null
    });
    const formRefForSign = ref();

    const handleSign = (val, type) => {
      signVisible.value = true;
    };

    const signLoading = ref(false);

    const handleCancelSign = () => {
      formRefForSign.value.resetFields();
      signVisible.value = false;
    };

    const handleOkSign = () => {
      formRefForSign.value.validate().then(async () => {
        const obj = {};
        Object.assign(obj, signState);
        signLoading.value = true;
        let res = await updateVisitorState(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRefForSign.value.resetFields();
          signLoading.value = false;
          signVisible.value = false;
          getTableData();
        } else {
          _message.error(res.message);

          signLoading.value = false;
        }
      }).catch(error => {
        console.log('error', error);
        signLoading.value = false;
      });
    }; //访客列表


    const visitorVisible = ref(false);

    const handleVisitor = () => {
      visitorVisible.value = true;
    };

    const closeVisitor = val => {
      visitorVisible.value = false;
    };

    const handleCopy = () => {
      const {
        protocol,
        host
      } = location;
      console.log('host', host);
      var textValue = document.createElement('textarea');
      textValue.setAttribute('readonly', 'readonly'); //设置只读属性防止手机上弹出软键盘

      switch (host) {
        case '121.37.145.240':
        case 'www.kndcim.com':
        case 'www.kndcim.com':
        case '121.37.145.240:443':
          textValue.value = `http://121.37.145.240/requestAccess`;
          break;

        default:
          textValue.value = `${protocol}//${host}/requestAccess`;
          break;
      } // textValue.value =`${protocol}//${host}/requestAccess`;


      document.body.appendChild(textValue); //将textarea添加为body子元素

      textValue.select();
      document.execCommand('copy');
      document.body.removeChild(textValue); //移除DOM元素

      _message.success("复制成功");
    };

    onMounted(() => {
      getTableData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      handleApply,
      applyVisible,
      closeApplyVisible,
      toView,
      applyDetailVisible,
      closeApplyDetailVisible,
      applyDetailData,
      isCompanyAdmin,
      updateDataValue,
      setBtnIsShow,
      setDeleteTitle,
      deleteItem,
      updateItem,
      applyAgainVisible,
      applyAgainData,
      closeApplyAgainVisible,
      handleAgain,
      handleSign,
      signVisible,
      signState,
      formRefForSign,
      signLoading,
      handleCancelSign,
      handleOkSign,
      visitorVisible,
      handleVisitor,
      closeVisitor,
      handleCopy
    };
  }

});