import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { ref, reactive, onMounted } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { VerticalAlignBottomOutlined, ProfileOutlined, RightOutlined, PartitionOutlined } from '@ant-design/icons-vue';
import { selectVisitorHisByPagination } from '@/api/administration';
import Cookies from "js-cookie";
import visitorDetailModal from './visitorDetailModal.vue';
const columns = [{
  title: '访问编号',
  dataIndex: 'visitorNo',
  align: 'center'
}, {
  title: '访客姓名',
  dataIndex: 'visitorName',
  align: 'center'
}, {
  title: '访客公司',
  dataIndex: 'visitorCompany',
  align: 'center'
}, {
  title: '手机号',
  dataIndex: 'mobilePhone',
  align: 'center'
}, {
  title: '接待人',
  dataIndex: 'receptName',
  align: 'center'
}, {
  title: '签到时间',
  dataIndex: 'signinTime',
  align: 'center'
}, {
  title: '签出时间',
  dataIndex: 'signoutTime',
  align: 'center'
}, {
  title: '操作',
  dataIndex: 'operation',
  align: 'center',
  slots: {
    customRender: 'operation'
  }
}];
export default {
  components: {
    VerticalAlignBottomOutlined,
    ProfileOutlined,
    RightOutlined,
    PartitionOutlined,
    visitorDetailModal
  },
  emits: ['closeVisitor'],

  setup(props, context) {
    const handleBack = () => {
      context.emit('closeVisitor', false);
    };

    const formState = reactive({
      visitorNo: null,
      visitorName: null,
      visitorCompany: null,
      receptName: null,
      date: []
    });
    const formRef = ref(); //表格

    const tableData = ref([]); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const tableLoading = ref(false);

    const getTableData = async () => {
      const {
        date
      } = formState;
      const [startTime, endTime] = date.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD');
        }

        return item.startOf('day').format('YYYY-MM-DD');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        visitorNo: formState.visitorNo,
        visitorName: formState.visitorName,
        visitorCompany: formState.visitorCompany,
        receptName: formState.receptName,
        startDate: startTime ? startTime : null,
        endDate: endTime ? endTime : null
      };
      tableLoading.value = true;

      try {
        let res = await selectVisitorHisByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          tableLoading.value = false;
        } else {
          tableLoading.value = false;

          _message.error(res.message);
        }
      } catch {
        tableLoading.value = false;
      }
    }; //点击查询


    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //详情


    const applyDetailVisible = ref(false);
    const applyDetailData = reactive({
      state: 1,
      // data:{}
      key: null,
      applyVisible: false
    }); //查看详情

    const toView = async (item, num) => {
      applyDetailData.state = num;
      applyDetailData.key = item.key;
      applyDetailData.applyVisible = true;
    }; //关闭详情页


    const closeApplyDetailVisible = val => {
      applyDetailData.applyVisible = val.isClose;

      if (val.state == 2) {
        getTableData();
      }
    };

    onMounted(() => {
      getTableData();
    });
    return {
      handleBack,
      columns,
      tableData,
      onPageChange,
      paginationConfig,
      formState,
      formRef,
      onSubmit,
      resetForm,
      tableLoading,
      applyDetailVisible,
      applyDetailData,
      toView,
      closeApplyDetailVisible
    };
  }

};